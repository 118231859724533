import React from "react";
import backgruond from "../../src/img/backgraphics.png";
import d1 from "../../src/img/d1.png";
import d2 from "../../src/img/d2.png";
import d3 from "../../src/img/d3.png";
import d4 from "../../src/img/d4.png";
import searchicon from "../../src/img/search.png";
import icon from "../../src/img/Path 318.png";
import MusicPlayer from "./MusicPlayer";
const Search = () => {
  return (
    <div
      className="Search relative h-[65rem] px-[5rem]  mt-[-15rem] flex items-center
     justify-between rounded-b-[5rem] z-[-3]  pb-[10rem]  bg-[#081730]"
    >
      {/* left*/}
      <div className="left  flex-1">
        <img
          src={backgruond}
          alt=""
          className="absolute top-[22rem] left-[-47rem] "
        />
        <img src={d1} alt="" className="absolute top-[26rem] w-[16rem]" />
        <img
          src={d2}
          alt=""
          className="absolute w-[9rem] top-[32.7rem] left-[7rem]"
        />
        <img
          src={d3}
          alt=""
          className="absolute w-[9rem] top-[33rem] left-[17rem]"
        />
        <img
          src={d4}
          alt=""
          className="absolute w-[17rem] top-[50rem] left-[2rem]"
        />
      </div>
      {/* right */}
      <div className="right flex flex-col items-start justify-center flex-1 h-[100%] pt-[9rem]">
        <div className="searchbar ml-5 flex justify-start w-[100%]">
          <input
            type="text"
            placeholder="Enter the Keyword or URL"
            className="flex-[19] outline-none rounded-xl p-3 h-[3rem] bg-[#020917]"
          />
          <div className="searchicon flex flex-1 items-center bg-gradient-to-bl from-[#f3071d] to-[#e600ff] p-4 h-[3rem] rounded-[8px] ml-[3rem] ">
            <img src={searchicon} alt="" className="w-[1.5rem]" />
          </div>
        </div>
        <div className="tild flex justify-start mt-7 ml-9 items-center w-[100%]">
          <img src={icon} alt="" className="w-[5rem]" />
        </div>
        <div className="dedils flex flex-col mt-5 text-4xl ml-9">
          <span>search Music by</span>
          <span>
            <b>Name or Direct URL</b>
          </span>
          <span className="text-sm mt-3 text-[#4d586a]">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Totam
            facilis,
            <br /> suscipit aliquam excepturi officia debitis?
          </span>
        </div>
        <MusicPlayer/>
      </div>
    </div>
  );
};

export default Search;
