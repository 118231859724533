import React from "react";
import mask from "../img/Mask Group 23.png";
import icon from "../img/path.png";
import group from "../img/Group 9.png";

const MusicPlayer = () => {
    const now = new Date();
const hours = now.getHours();
const minutes = now.getMinutes();
const Seconds = now.getSeconds();
  return (
    <div className="MusicPlayer flex flex-col px-5 w-[100%] border-2 ml-5 mt-2 border-black py-3 bg-[#232a4e] rounded-xl">
      <div className="upperpart flex items-center justify-between">
        <div className="profile flex">
          <img
            src={mask}
            alt=""
            className="w-[2.5rem] h-[2.5rem] border-2 border-white rounded-full"
          />
          <div className="details flex flex-col ml-4">
            <span className="text-[1rem]">Tristam Bone Dry</span>
            <span className="text-sm text-gray-500">Tristam Bone Dry</span>
          </div>
        </div>
        <div>
          <img src={icon} alt="" className="h-[1rem]" />
        </div>
      </div>
      <div className="lowerpart">
        <div className="track flex justify-between text-ms text-gray-500 flex-2"> 
        <span>{`${hours}:${minutes}`}</span>
        <img src={group} className="w-[16rem]" alt="" />
        <span>{`${hours}:${Seconds}`}</span>
        </div>
        <div className="controls">
        <svg width='10.455' height='12.442' viewBox="0 0 10.544 12.442"></svg>
        </div>
      </div>
    </div>
  );
};

export default MusicPlayer;
