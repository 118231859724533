import React from "react";
import story from "../../src/img/App Store.png";
import google from "../../src/img/Google Play.png";
const DownloadAds = () => {
    const imgstyle = "border=[2px] border=[#232a4e] rounded-[13px] h-[3rem] w-[10rem]"
  return (
    <div className="download">
      <div className="dowmload-imgs flex ">
        <img src={story} alt="" className={imgstyle + ' mr-10'}/>
        <img src={google} alt="" className={imgstyle}/>
      </div>
    </div>
  );
};

export default DownloadAds;
