import React from "react";

const CinterMinu = () => {
    const listyle = "mr-[3rem] hover:cursor-pointer"
  return (
    <div className="menu flex">
      <ul className="flex w-[100%] justify-between">
        <li className={listyle}>Home</li>
        <li className={listyle}>About</li>
        <li className={listyle}>Preformer</li>
        <li className={listyle}>Event Sheule</li>
      </ul>
    </div>
  );
};

export default CinterMinu;
