import React from "react";
import CenterMinu from "./CinterMinu";
import { FaFacebook } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";
// import { Facebook, Twitter, YouTube, LinkedIn } from "@material-ui/icons";
const Footer = () => {
    const socialStyle= "rounded-full border-2 border-white p-2 mr-[5rem]"
  return (
    <div className="Footer flex flex-col items-center justify-start px-[5rem] bg-[#081730] h-[45rem] pt-[18rem] relative z-[-49] mt-[-10rem]">
      <CenterMinu />
      <div className="flex w-[100%] justify-center mt-14 gap-9">
        <div className={socialStyle}>
          <FaFacebook size={23} />
        </div>
        <div className={socialStyle}>
          <FaXTwitter size={23} />
        </div>
        <div className={socialStyle}>
          <FaYoutube size={23} />
        </div>
        <div className={socialStyle}>
          <FaLinkedinIn size={23} />
        </div>
      </div>

      <span className="text-[1rem] text-gray-400 px-[15rem] text-center mt-[4rem]">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Praesentium.
      </span>
    </div>
  );
};

export default Footer;
