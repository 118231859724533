import React from "react";
const Feature = (props) => {
  return (
    <div className="feature flex items-center flex-col justify-center relative mx-12 text-center">
      {/* icon */}
      <div className="icon bg-[#081730] rounded-2xl p-4">
        <img
          src={require(`../../src/img/${props.icon}.png`)}
          alt=""
          className="w-[3rem]"
        />
      </div>
      <span className="mt-5">{props.title}</span>
      <span className="text-[#707070] mt-4">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos
        explicabo eligendi commodi saepe doloremque voluptatibus vel! 
      </span>
      <span className="text-[#e600ff] underline mt-[3rem] hover:cursor-pointer">
        Learn more
      </span>
    </div>
  );
};

export default Feature;
