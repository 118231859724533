import React from "react";
import logo from "../../src/img/MuzicLogo.png";
import CinterMinu from "./CinterMinu";
const Headar = () => {
  const btnStyle =
    "border-[2px] rounded-[10px] border-[#232a4e] px-[25px] py-[7px]";
  return (
    <div className="Headar bg-[#081730] flex items-center justify-between px-20 pt-10 text-[0.8rem]">
      <img src={logo} alt="" className="logo w-[42px] h-[42px]" />
      <CinterMinu />
      <div className="buttons flex">
        <button className={`mr-[35px] hover:bg-[#232a4e] ` + btnStyle}>
          Sing in
        </button>
        <button className={` bg-[#232a4e] ` + btnStyle}>Login</button>
      </div>
    </div>
  );
};

export default Headar;
