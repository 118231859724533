import logo from './logo.svg';
import './App.css';
import Headar from './Components/Headar';
import Hero from './Components/Hero';
import Experince from './Components/Experince';
import Search from './Components/Search';
import Download from './Components/Download';
import Footer from './Components/Footer';

function App() {
  return (
    <div className="App text-white overflow-hidden">
      <Headar/>
      <Hero/>
      <Experince/>
      <Search/>
      <Download/>
      <Footer/>
    </div>
  );
}

export default App;
