import React from "react";
import lineCarve from "../../src/img/Path 318.png";
import Feature from "./feature";
const Experince = (props) => {
  return (
    <div className="Experince flex flex-col items-center justify-start h-[60rem] bg-[#020917]
    pt-[18rem] mt-[-10rem] relative z-[-2] rounded-[5rem]">
    {/* title */}
      <img src={lineCarve} alt="" />
      {/* heading */}
      <div className="headline mt-7 flex flex-col items-center text-[2rem]">
      <span>An Amazing App Change Your Daily Life</span>
      <span><b>Music Experince</b></span>
      </div>
      {/* feature */}
      <div className="feature flex items-center justify-around mt-[5rem] w-[100%]">
      
      <Feature icon='Group 2' title='For Music'/>
      <Feature icon='music icon' title='For Daily'/>
      <Feature icon='Group 4' title='For Artists'/>
      </div>
    </div>
  );
};

export default Experince;
