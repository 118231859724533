import React from "react";
import DownloadAds from "./DownloadAds";
import backgruond from '../../src/img/backgraphics.png'
import p1 from '../../src/img/p 1.png'
import p2 from '../../src/img/p 2.png'
import p3 from '../../src/img/p 3.png'
import p4 from '../../src/img/p 4.png'
const Hero = () => {
  return (
    <div className="wrapper bg-[#081730] flex items-center justify-between px-[5rem] rounded-b-[5rem] w-[100%] h-[35rem] relative">
      {/* left side */}
      <div className="headings flex flex-col items-start justify-center h-[100%] text-[3rem]">
        <span>Experince The</span>
        <span>
          <b>Best Qulaity Music</b>
        </span>
        <span className="text-[15px] text-[#525d6e]">
        Donse laoreet nec velit vitae aliquam. Ut quis tincidunt purus
        <br />
        Sispendisse in leo non risus tincidunt lobortis.
        </span>
        <div>
        <span className="text-[13px] "> Download now on ios and android</span>
        <DownloadAds/>
        </div>
      </div>
      {/* right side */}
      <div className="images relative w-[50%]">
      <img src={backgruond} alt="" className="absolute top-[-8rem] left-[19rem]"/>
      <img src={p1} alt="" className="absolute top-[-15rem] left-[13rem] h-[34rem]" />
      <img src={p2} alt="" className="absolute top-[94px] left-[235px] w-[175px]" />
      <img src={p3} alt="" className="absolute top-[12rem] left-[13rem] w-[5rem]" />
      <img src={p4} alt=""  className="absolute top-[12rem] left-[200px] w-[5rem]"/>
      </div>
    </div>
  );
};

export default Hero;
