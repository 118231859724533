import React from "react";
import icon from "../../src/img/Path 318.png";
import DowmloadAds from '../Components/DownloadAds'
const Download = () => {
  return (
    <div className="flex flex-col items-center justify-start px-[5rem] bg-[#020917] h-[48rem] pt-[18rem] mt-[-10rem] relative z-[-9] rounded-b-[5rem]">
      <img src={icon} alt="" className="w-[5rem]" />
      <div className="hadline mt-7 flex flex-col items-center text-[2rem]">
        <span>Download The Best Music</span>
        <span>
          <b>App Now!</b>
        </span>
        <span className="text-[1rem] text-gray-400 px-[15rem] text-center mt-[1rem]">
          Lorem ipsum dolor sit amet, consectetur adipisicing.
        </span>
      </div>
      <DowmloadAds/>
    </div>
  );
};

export default Download;
